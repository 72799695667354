import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import qs from "query-string";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("hblELearning"));

  if (user?.token) {
    return true;
  } else {
    return false;
  }
};

// const useNCC = () => {
//   const user = JSON.parse(localStorage.getItem("hblELearning"));
//   const role = user?.account?.roleDescription?.group;

//   if (role === "ncc") {
//     return true;
//   } else {
//     return false;
//   }
// };

export default function PrivateRoute() {
  const isAuth = useAuth(); // Có TOKEN
  // const isNCC = useNCC(); // Là NCC

  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const isLogout = queryParams?.logout;

  // const user = JSON.parse(localStorage.getItem("hblELearning"));

  const handleLogout = () => {
    localStorage.removeItem("hblELearning");
    localStorage.removeItem("optionsData");
    localStorage.removeItem("isAdminPanel");
    return <Navigate to="/login" />;
  };

  return isLogout === "true" ? (
    handleLogout()
  ) : isAuth ? (
    // isNCC
    // ? (
    <Outlet />
  ) : (
    // ) : (
    //   // (window.location.href = `${process.env.REACT_APP_LINK_ADMIN}?shortToken=${user?.shortToken}`)
    //   <Navigate to={`/options?isTab=true&shortToken=${user?.shortToken}`} />
    // )
    <Navigate to="/login" />
  );
}
