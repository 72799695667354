import React, { useEffect } from "react";
import Header from "./Header";
import Fooder from "./Footer";
import { Outlet, useNavigate } from "react-router-dom";

export default function MainLayout() {
  const user = JSON.parse(localStorage.getItem("hblELearning"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.token) {
      return navigate("/login");
    } else {
      const validDataEncoding = user?.validDataEncoding;
      const isAdminPanel = JSON.parse(localStorage.getItem("isAdminPanel"));
      const shortToken = user?.shortToken;
      const role = user?.account?.roleDescription?.group;

      if (role !== "ncc" && isAdminPanel) {
        return (window.location.href = `${process.env.REACT_APP_LINK_ADMIN}?shortToken=${shortToken}`);
      }

      if (!validDataEncoding) {
        navigate("/identity");
      }
      // const role = user?.account?.roleDescription?.group;
      // if (role === "ncc") {
      //   const validDataEncoding = user?.validDataEncoding;
      //   if (!validDataEncoding) {
      //     navigate("/identity");
      //   }
      // } else {
      //   const shortToken = user?.shortToken;
      //   // window.location.href = `${process.env.REACT_APP_LINK_ADMIN}?shortToken=${shortToken}`;
      //   navigate(`/options?isTab=true&shortToken=${shortToken}`);
      // }
    }
  }, [navigate, user]);

  return (
    <>
      <Header />
      <Outlet />
      <Fooder />
    </>
  );
}
