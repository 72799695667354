import React from "react";
import useCamera from "../hooks/useCamera";

const CamContext = React.createContext();
const CameraProvider = ({ children }) => {
  const props = useCamera();

  return <CamContext.Provider value={props}>{children}</CamContext.Provider>;
};

export const useCamContext = () => {
  const context = React.useContext(CamContext);
  if (!context) throw Error("Not have context");
  return context;
};

export default CameraProvider;
