import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import "./index.css";

export default function Footer() {
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")?.[1];

  if (currentPath === "test") {
    return;
  }

  return (
    <>
      <footer className="wrapper">
        <div className="container footer">
          <div className="footer-left">
            <Link to="/home">
              <img src="/img/logo-footer-v2.png" alt="logo-footer" />
            </Link>
          </div>
          <div className="footer-right">
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <p style={{ fontWeight: 700 }}>
                  Copyright © Herbalife International of America, Inc. CÔNG TY
                  TNHH MTV HERBALIFE VIỆT NAM
                </p>
                <p>
                  Trụ sở chính: 26 Đường Trần Cao Vân, Phường Võ Thị Sáu, Quận
                  3, Tp.Hồ Chí Minh
                </p>
                <a
                  href="https://www.herbalife-vietnam.com/footer-pages/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chính sách về Quyền riêng tư trực tuyến toàn cầu của Herbalife
                  Nutrition
                </a>
              </Col>
              <Col xs={24} lg={12}>
                <p>
                  Giấy Chứng nhận Đăng ký Doanh nghiệp số 0309069208, cấp bởi Sở
                  Kế Hoạch và Đầu Tư TP.HCM.
                </p>
                <p>
                  Giấy CN Đăng ký Hoạt động Bán hàng đa cấp số 020/QLCT-GCN, cấp
                  bởi Cục Quản lý Cạnh tranh (nay là Cục Cạnh tranh và Bảo vệ
                  Người tiêu dùng) - Bộ Công Thương.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
      {/* <div className="policy-and-privacy">
        <a
          href="https://www.herbalife-vietnam.com/footer-pages/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chính sách về Quyền riêng tư trực tuyến toàn cầu của Herbalife
          Nutrition
        </a>
      </div> */}
    </>
  );
}
