import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./index.css";
import useViewPort from "../../hooks/useViewPort";

Logo.propTypes = {
  isLink: PropTypes.bool,
};

Logo.defaultProps = {
  isLink: false,
};

export default function Logo({ isLink }) {
  const viewPort = useViewPort();

  return (
    <div className="logo">
      {isLink ? (
        <Link to="/home" className="logo-link">
          {viewPort <= 500 ? (
            <img srcSet="/img/logo-header-mb-link.png 2x" alt="logo" />
          ) : (
            <img src="/img/logo-header-v2.png" alt="logo" />
          )}
        </Link>
      ) : (
        <div className="logo-link">
          {viewPort <= 500 ? (
            <img srcSet="/img/logo-header-mb.png 2x" alt="logo" />
          ) : (
            <img src="/img/logo-header-v2.png" alt="logo" />
          )}
        </div>
      )}
      <span>Trung tâm huấn luyện</span>
    </div>
  );
}
