import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import Logo from "../Logo";
import { Button, Dropdown, Menu, message } from "antd";
import { useMutation } from "react-query";
import authApi from "../../api/authApi";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")?.[1];
  const user = JSON.parse(localStorage.getItem("hblELearning"));

  const logout = useMutation(authApi.logout, {
    onSuccess: () => {
      message.success("Đăng xuất thành công");
      localStorage.removeItem("hblELearning");
      navigate("/login", { replace: true });
    },
    onError: () => {
      message.error("Đăng xuất thất bại");
    },
  });

  const menu = (
    <Menu
      className="header-drop-down"
      items={[
        {
          label: (
            <Link to="/info">
              <UserOutlined /> Cá nhân
            </Link>
          ),
          key: "0",
        },
        {
          label: (
            <>
              <LogoutOutlined /> Đăng xuất
            </>
          ),
          key: "1",
          onClick: () => logout.mutate(),
        },
      ]}
    />
  );

  if (currentPath === "test") {
    return;
  }

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        background: "#fff",
        zIndex: 999,
      }}
    >
      <header className="wrapper">
        <div className="container header">
          <Logo isLink />
          <ul className="menu">
            <li className="menu-item">
              <NavLink to="/home">Trang chủ</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/courses">Khóa học</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/calendar">Lịch thi</NavLink>
            </li>
            {user?.account?.roleDescription?.group !== "ncc" && (
              <li className="menu-item">
                <a
                  href={`${process.env.REACT_APP_LINK_ADMIN}?shortToken=${user?.shortToken}`}
                >
                  Quản lý
                </a>
              </li>
            )}
          </ul>
          <div style={{ display: "flex" }}>
            <div className="header-info">
              <h2>{user?.account?.displayName}</h2>
            </div>
            <div className="header-avt">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
                arrow
              >
                <Button shape="circle" icon={<UserOutlined />} />
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
