import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Lottie from "lottie-react";
import Loading from "./lotties/loading.json";
import MainLayout from "./components/MainLayout";
import PrivateRoute from "./components/PrivateRoute";
import React, { Suspense, useLayoutEffect } from "react";
import moment from "moment-timezone";
import { useCamContext } from "./providers/CameraProvider";
moment.tz.setDefault("Asia/Ho_Chi_Minh");

const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const Courses = React.lazy(() => import("./pages/Courses"));
const MainDetail = React.lazy(() => import("./pages/MainDetail"));
const Calendar = React.lazy(() => import("./pages/Calendar"));
const NewsDetail = React.lazy(() => import("./pages/NewsDetail"));
// const TestDetail = React.lazy(() => import("./pages/TestDetail"));
const Info = React.lazy(() => import("./pages/Info"));
const Identity = React.lazy(() => import("./pages/Identity"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
// const Regulation = React.lazy(() => import("./pages/Regulation"));
const RegulationV2 = React.lazy(() => import("./pages/RegulationV2"));
const TestDetailV2 = React.lazy(() => import("./pages/TestDetailV2"));
const Options = React.lazy(() => import("./pages/Options"));

function App() {
  let location = useLocation();
  let { show, setShow } = useCamContext();

  useLayoutEffect(() => {
    if (show) {
      return () => {
        setShow(false);
      };
    }
  }, [location, setShow, show]);
  return (
    <div className="App">
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie animationData={Loading} autoPlay={true} loop={true} />
          </div>
        }
      >
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Navigate to="/home" />} />
              <Route path="home" element={<Home />} />
              <Route path="courses" element={<Courses />} />
              <Route path="courses/:courseId" element={<MainDetail />} />
              <Route path="test/:testId" element={<TestDetailV2 />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="info" element={<Info />} />
              <Route path="news/:newsId" element={<NewsDetail />} />
              <Route
                path="regulation/:idExamProfile/:id"
                element={<RegulationV2 />}
              />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/identity" element={<Identity />} />
          <Route path="/options" element={<Options />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
