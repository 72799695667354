import axiosClient from "./axiosClient";

const authApi = {
  login(data) {
    const url = "login";
    return axiosClient.post(url, data);
  },
  logout() {
    const url = "logout";
    return axiosClient.post(url);
  },
  sendOTP(data) {
    const url = "send-otp";
    return axiosClient.post(url, data);
  },
  verifyOTP(data) {
    const url = "verify-otp";
    return axiosClient.post(url, data);
  },
  changePassword({ data, token }) {
    const url = "init-password";
    return axiosClient.post(url, data, {
      headers: { Authorization: token },
    });
  },
  changePasswordInWeb({ id, data }) {
    const url = `change-password/${id}`;
    return axiosClient.post(url, data);
  },
  detailProfile() {
    const url = "detail-profile";
    return axiosClient.get(url);
  },
  getEncoding(data) {
    const url = "web/get-feature-face";
    return axiosClient.post(url, data, {
      baseURL: process.env.REACT_APP_GATEWAY_API,
    });
  },
  confirmProfile(data) {
    const url = "confirm-profile";
    return axiosClient.post(url, data);
  },
  forgotPassword({ data, token }) {
    const url = "forgot-password";
    return axiosClient.post(url, data, {
      headers: { Authorization: token },
    });
  },
  getInfo() {
    const url = "info";
    return axiosClient.get(url);
  },
  setMember(data) {
    const url = "set-member";
    return axiosClient.post(url, data);
  },
};

export default authApi;
