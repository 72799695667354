import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { isFirefox } from "react-device-detect";
import html2canvas from "html2canvas";

let cameraHeight = 480;
const useCamera = () => {
  //camera status - 0: no device camera, 1: loading, 2: loaded
  //countdown status - 0: none, 1: loading, 2: loaded
  const [show, setShow] = useState(false);
  const [isAllow, setAllow] = useState(false);
  const [haveCamera, setHaveCamera] = useState(1);
  const [isReloadCam, setIsReloadCam] = useState(undefined);
  const [countdownStatus, setCountdownStatus] = useState(0);
  const [currentVideoRef, setCurrentVideoRef] = useState();
  const [replayCam, setReplayCam] = useState(undefined);
  const ref = useRef();
  const videoTracker = useRef();

  const checkMediaDevicesQuantity = (cb, onError) => {
    navigator?.mediaDevices
      ?.enumerateDevices()
      .then((devices) => {
        if (cb) {
          cb(devices);
        }
      })
      .catch((err) => {
        if (onError) return onError(err);
        console.log("err", err);
      });
  };

  useEffect(() => {
    //check quantity of media devices on pc or laptop
    checkMediaDevicesQuantity((devices) => {
      if (devices.length === 0) {
        setHaveCamera(0);
        console.log("Thiết bị không có sẵn Camera");
      }
    });
  }, [haveCamera]);

  useEffect(() => {
    if (countdownStatus === 1 || replayCam) {
      let previewImg = document.querySelector(".preview-box");
      if (previewImg) {
        previewImg.innerHTML = null;
      }
      setReplayCam(undefined);
    }
  }, [countdownStatus, replayCam]);

  useEffect(() => {
    // listen camera permission status change
    let camera_perm = undefined;
    function handleCameraEvent() {
      const allowed =
        camera_perm.state === "granted" || camera_perm.state === "prompt";
      if (!allowed) {
        setAllow(false);
      } else {
        setAllow(true);
      }
    }

    async function checkCameraPermission() {
      camera_perm = await navigator.permissions.query({ name: "camera" });
      handleCameraEvent();
      camera_perm.addEventListener("change", handleCameraEvent);
    }
    checkCameraPermission();
    return () => {
      if (camera_perm) {
        camera_perm.removeEventListener("change", handleCameraEvent);
      }
    };
  }, []);

  useEffect(() => {
    const listenVideoEnded = () => {
      // listen video ended => turn off media device (camera)
      // check quantity devices to display camera status
      // if have 2 media devices -> change rest camera
      // else display none camera status (0)
      checkMediaDevicesQuantity((devices) => {
        if (devices.length === 0) {
          setHaveCamera(0);
          console.log("Thiết bị không có sẵn Camera");
        } else {
          setHaveCamera(1);
          onReloadCamera();
        }
      });
    };
    const listenVideoOnLoad = () => {
      //listen video loaded to stop loading video
      setHaveCamera(2);
    };

    // reset status loading video
    setHaveCamera(1);
    //display camera after click
    if (currentVideoRef) {
      ref.current = currentVideoRef.current;
    }
    if (show && ref.current) {
      const video = ref.current;
      if (!video.srcObject) {
        if (navigator && navigator.mediaDevices) {
          //stream camera to video
          navigator.mediaDevices
            .getUserMedia({
              video: {
                height: cameraHeight,
              },
              audio: false,
            })
            .then((stream) => {
              if (video) {
                if (isFirefox) {
                  setAllow(true);
                }
                video.srcObject = stream;
                videoTracker.current = video.srcObject.getVideoTracks()[0];
                videoTracker.current.addEventListener(
                  "ended",
                  listenVideoEnded
                );

                ref.current.addEventListener("loadeddata", listenVideoOnLoad);
                video.play();
              }
            })
            .catch((err) => {
              if (isFirefox) {
                setAllow(false);
              }
              if (isAllow) {
                setHaveCamera(0);
              }
              if (err.name === "NotAllowedError") {
                console.log("Vui lòng cho phép truy cập camera");
                if (video.srcObject) {
                  video.srcObject = undefined;
                }
              }
            });
        }
      }

      return () => {
        if (video.srcObject) {
          videoTracker.current.removeEventListener("ended", listenVideoEnded);
          ref.current.removeEventListener("loadeddata", listenVideoOnLoad);
          video?.srcObject?.getTracks()[0].stop();
          video.srcObject = undefined;
        }
      };
    }
  }, [show, isAllow, isReloadCam, ref, currentVideoRef]);

  const handleCanvas = (canvas, callback) => {
    canvas?.toBlob((blob) => {
      let file = new File([blob], `${uuidv4()}.png`, {
        lastModified: new Date().getTime(),
        type: blob?.type,
      });

      if (file) {
        const fmData = new FormData();
        fmData.append("my-file", file);
        fmData.append("path", "elearning/");
        if (callback && typeof callback === "function") {
          callback(fmData, file);
        } else {
          console.error("must have mutate API");
        }
      }
    });
  };

  const takePhoto = useCallback(
    (callback, { isGetTakePhotoTime, isPreview, isScale, isSquare } = {}) => {
      if (haveCamera !== 0 && isAllow) {
        let videoBox = document.querySelector(".video-box");
        let { clientWidth: canvasW, clientHeight: canvasH } = videoBox;
        let video = ref.current;
        let videoAspectRatio = cameraHeight / video.clientHeight;
        const canvas = document.createElement("canvas");
        canvas.width = canvasW; // widthImage;
        canvas.height = canvasH; //heightImage;

        let ctx = canvas.getContext("2d");
        if (isScale) {
          ctx.scale(-1, 1);
        }

        let sx = (video.clientWidth - canvasW) / 2;
        if (isSquare) {
          ctx.drawImage(
            video,
            sx * videoAspectRatio,
            0,
            isScale
              ? canvasW * videoAspectRatio * -1
              : canvasW * videoAspectRatio,
            canvasH * videoAspectRatio,
            0,
            0,
            canvasW,
            canvasH
          );
        } else {
          ctx.drawImage(
            video,
            sx,
            0,
            isScale ? canvasW * -1 : canvasW * 1,
            canvasH
          );
        }

        if (isGetTakePhotoTime) {
          drawTimeTakePhoto(ctx, canvas, isScale);
        }
        if (isPreview) {
          let previewImg = document.querySelector(".preview-box");
          let img = document.createElement("img");
          img.src = canvas.toDataURL();
          previewImg.append(img);
        }

        handleCanvas(canvas, callback);
      }
    },
    [haveCamera, isAllow]
  );

  useEffect(() => {
    let takePhotoController = function (e) {
      if (e.ctrlKey && e.key === "/") {
        takePhoto();
      }
    };
    document.addEventListener("keydown", takePhotoController);
    return () => {
      document.removeEventListener("keydown", takePhotoController);
    };
  }, [takePhoto]);

  const onReloadCamera = () => {
    setIsReloadCam(Math.random());
  };

  const drawTimeTakePhoto = (ctx, canvas, isScale) => {
    ctx.font = "bold 20px Arial";
    if (isScale) {
      ctx.scale(-1, 1);
    }

    let currentTime = moment().format("DD-MM-YYYY HH:mm:ss");
    var currentTimeWidth = ctx.measureText(currentTime).width;

    ctx.fillStyle = "black";
    ctx.strokeStyle = "#ffffff";
    ctx.lineWidth = 0.5;

    ctx.fillText(
      currentTime,
      canvas.width * 1 - currentTimeWidth - 5,
      canvas.height * 1 - 5
    );

    ctx.strokeText(
      currentTime,
      canvas.width * 1 - currentTimeWidth - 5,
      canvas.height * 1 - 5
    );
  };

  const takeScreenshot = (callback) => {
    html2canvas(document.body).then(function (canvas) {
      let ctx = canvas.getContext("2d");

      drawTimeTakePhoto(ctx, canvas);

      handleCanvas(canvas, callback);
    });
  };

  return {
    show,
    setShow,
    ref,
    setCurrentVideoRef,
    countdownStatus,
    setCountdownStatus,
    takePhoto,
    isAllow,
    haveCamera,
    setHaveCamera,
    onReloadCamera,
    setReplayCam,
    takeScreenshot,
  };
};

export default useCamera;
