import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("hblELearning"));

    if (user?.token) {
      config.headers.Authorization = user?.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const code = error?.response?.data?.errors?.[0]?.code;

    if (code === 101 || code === 102 || code === 111) {
      localStorage.removeItem("hblELearning");
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
